import styled from 'styled-components'
import { device } from '../../theme/device'

const Button = styled.button`
  width: 100%;  
  background-color: ${({ theme, active }) => (active ? 'transparent' : theme.colors.primary)};
  border: ${({ theme, active }) => (active ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.primary}`)};
  max-width: ${(props) => (props.width ? props.width.m : '100%')};
  padding: ${(props) => (props.padding ? props.padding.m : 0)};
  margin: ${(props) => (props.margin ? props.margin.m : 0)};
  transition: all 0.2s;
  height: 40px;
  color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.white)};
  font-family: ${({ theme }) => theme.font.sofia};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.hover)};
    border: ${({ theme, active }) => (active ? `1px solid ${theme.colors.primary}` : theme.colors.hover)};
  }
  &:disabled {
    opacity: 0.4;
    cursor: initial;
  }

  @media ${device.tablet} {
    width: 100%; 
    max-width: ${(props) => (props.width ? props.width.d : '100%')};
    height: 40px;
    padding: ${(props) => (props.padding ? props.padding.d : 0)};
    margin: ${(props) => (props.margin ? props.margin.d : 0)};
  }
`

export default Button
