import React, { useEffect, useState } from 'react'
import ToTopIcon from '../../assets/icons/tam_icon_backtotop.svg'
import './scrollTop-styles.scss'

const scrollTo = ({
  showBelow,
}) => {
  const [show, setShow] = useState(!showBelow)

  const handleScroll = () => {
    if (typeof window !== 'undefined' && window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else if (show) setShow(false)
  }

  const handleClick = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && showBelow) {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <div>
      {show
        && <div onClick={handleClick} className="button-top" aria-label="to top" component="span">
          <ToTopIcon width="20" />
        </div>
      }
    </div>
  )
}

export default scrollTo
