/* eslint-disable global-require */
import i18next from 'i18next'
import detector from 'i18next-browser-languagedetector'

i18next
  .use(detector)
  .init({
    // lng: 'en',
    defaultLanguage: 'en',
    fallbackLng: ['en', 'ja', 'zh-hant'],
    resources: {
      ja: {
        translations: require('./locales/jp.json'),
      },
      en: {
        translations: require('./locales/en.json'),
      },
      'zh-Hant': { 
        translations: require('./locales/zh-hant.json'),
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
    },
  })


i18next.languages = ['en', 'ja', 'zh-hant']

export default i18next
