import * as React from "react"
import wrapWithProvider from './src/connect'

export const onRenderBody = ({ setHeadComponents }) => {
	setHeadComponents([
		<link
			rel="preload"
			href="/fonts/IvarDisplayHydro-Regular.ttf"
			as="font"
			type="font/ttf"
			crossOrigin="anonymous"
			key="IvarDisplayHydroTTF"
		/>,
		<link
			rel="preload"
			href="/fonts/IvarDisplayHydro-Regular.eot"
			as="font"
			type="font/eot"
			crossOrigin="anonymous"
			key="IvarDisplayHydroEOT"
		/>,
		<link
			rel="preload"
			href="/fonts/IvarDisplayHydro-Regular.woff"
			as="font"
			type="font/woff"
			crossOrigin="anonymous"
			key="IvarDisplayHydroWOFF"
		/>,
		<link
			rel="preload"
			href="/fonts/IvarDisplayHydro-Regular.woff2"
			as="font"
			type="font/woff2"
			crossOrigin="anonymous"
			key="IvarDisplayHydroWOFF2"
		/>,
	])
}

export const wrapRootElement = wrapWithProvider