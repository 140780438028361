import React from 'react'
import styled from 'styled-components'

export const Span = styled.span`
  font-size: ${({ theme, two }) => (two ? theme.fontSize.sm : theme.fontSize.sm)};
  font-family: ${({ theme }) => theme.font.sofia};
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.white)};
  &:hover {
    text-decoration: underline;
    color: ${(props) => (props.theme.colors.primary)};
  }
`

const ButtonText = ({
  children,
  ...restProps
}) => (
  <Span
    {...restProps}
  >
    {children}
  </Span>
)

export default ButtonText
