import _ from 'lodash'
import React, {
  useState,
  useEffect,
  useRef
} from 'react'
import moment from 'moment'
import { useLocation } from '@reach/router'
import Select from 'react-select'
import { Link, navigate } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
// import DatePicker, { utils } from '@amir04lm26/react-modern-calendar-date-picker'
import * as styles from './overlap-book-styles.module.scss'
import { useBooking } from '../../hooks/use-booking'
import getAllUrlParams from '../../helpers/get-query-params'
import logoOrange from '../../assets/logo/kamo-logo-bg.jpg'
import LeftArrow from '../../assets/icons/reflections-group-icon-left-arrow.svg'
import Button from '../Button'
import DatePicker, { DateObject, getAllDatesInRange } from "react-multi-date-picker"
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import { useTranslation } from 'react-i18next'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange, DateRangePicker } from 'react-date-range';
import rightIcon from '../../assets/images/right-icon.svg'
import { useCalendar } from '../../hooks/use-calendar'

const OverlapBooking = ({
  onHandledOpenBooking,
  t,
  // title,
}) => {
  const location = useLocation()
  const { href, pathname } = location
  const parametres = getAllUrlParams(href)
  const [diffMessage, setDiffMessage] = useState(null)
  const {
    setTotalPerson,
    setTotalChildren,
    setTotalAdults,
    totalChildren,
    totalAdults,
    diffDays,
    setDiffDays,
    err,
    setBookTo,
    setBookFrom,
  } = useBooking()
  const {
	onHandleCalendar,
	isOpenCalendar
  } = useCalendar()
  const breakpoints = useBreakpoint()
  const datePickerRef = useRef();
  const [showPicker, setShowPicker] = useState(false);

  const { translate, i18n } = useTranslation();
  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if(activeLanguage) {
        switch(activeLanguage){
            case 'ja':
                link = '/ja/';
                break;
            case 'zh-hant':
                link = '/zh-hant/';
                break;
            default: link = '/';
        }
    }
    return link;
  }

  const options = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
  ]

  const optionsChild = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
  ]

const customStyles = {
	indicatorsContainer: (provided) => ({
	  ...provided,
	  backgroundColor: 'transparent',
	  border: 'none',
	}),
	indicatorSeparator: (provided) => ({
	  ...provided,
	  display: 'none',
	}),
	control: (provided) => ({
	  ...provided,
	  backgroundColor: 'transparent',
	  borderRadius: '0',
	  border: '0 !important',
	  borderBottom: '1px solid #000 !important',
	  boxShadow: 'none !important',
	  fontFamily: 'Sofia Pro Regular',
	  // none of react-select's styles are passed to <Control />
	  width: '100%',
	}),
	menuList: (provided) => ({
	  ...provided,
	  fontFamily: 'Sofia Pro Regular',
	}),
	menu: (provided) => ({
	  ...provided,
	  zIndex: 2,
	}),
	option: (provided, state) => ({
	  ...provided,
	  backgroundColor: state.data.value === state.selectProps.value.value
		? 'rgba(216, 112, 33, 1)'
		: 'transparent',
	  '&:hover': {
		backgroundColor: 'rgba(216, 112, 33, 0.4)',
	  },
	  '&:active': {
		backgroundColor: 'rgba(216, 112, 33, 0.4)',
	  },
	}),
	singleValue: (provided) => ({
	  ...provided,
	  lineHeight: '27px'
	})
  }
  const defaultFrom = {
    year: parametres ? moment(_.get(parametres, 'startdate')).year() : moment().year(),
    month: parametres ? moment(_.get(parametres, 'startdate')).month() + 1 : moment().month() + 1,
    day: parametres ? moment(_.get(parametres, 'startdate')).date() : moment().date(),
  }
  const pastTommorow = moment().add(2, 'd')
  const defaultTo = {
    year: !_.isEmpty(parametres) ? moment(_.get(parametres, 'enddate')).year() : moment(pastTommorow).year(),
    month: !_.isEmpty(parametres) ? moment(_.get(parametres, 'enddate')).month() + 1 : moment(pastTommorow).month() + 1,
    day: !_.isEmpty(parametres) ? moment(_.get(parametres, 'enddate')).date() : moment(pastTommorow).date(),
  }

  const defaultRange = [
    new DateObject(defaultFrom),
    new DateObject(defaultTo),
  ]

  const defaultRangeNew = {
		startDate: _.get(parametres, 'startdate') ? new Date(_.get(parametres, 'startdate')) : new Date(),
		endDate: _.get(parametres, 'enddate') ? new Date(_.get(parametres, 'enddate')) : new Date(moment().add(2, 'd')),
		key: 'selection'
	}

//   console.clear();
//   console.log(defaultRangeNew);
  
  const [selectedDayRange, setSelectedDayRange] = useState(defaultRange)

  const [selectedDayRanges, setSelectedDayRanges] = useState(defaultRangeNew)

//   const handleShowPicker = () => {

//   }

  useEffect(() => {
	// if(showPicker){
		// setSelectedDayRanges(defaultRangeNew)
	// }
	// onHandleCalendar(showPicker)
  }, [showPicker])

  useEffect(() => {
	if(selectedDayRanges){
		const { startDate, endDate } = selectedDayRanges;

		setSelectedDayRange([
			new DateObject(startDate),
	   	 	new DateObject(endDate),
		])
	}
  }, [selectedDayRanges])

  useEffect(() => {
	// console.log(selectedDayRange)
	const values = selectedDayRange;
	if (values && values[0] && values[1]) {
		const startDay = [values[0].year, values[0].month.number - 1, values[0].day]
		const endDay = [values[1].year, values[1].month.number - 1, values[1].day]
		const diff = moment.duration(moment(endDay).diff(moment(startDay))).asDays()
  
		console.log("DIFF", diff);
		setDiffDays(diff)
  
		if (diff < 1) {
		  setDiffMessage(t('navbarBooking.errors.more'))
		} else {
		  setDiffMessage(null)
		}
  
		setBookFrom(moment(startDay).format('YYYY-MM-DD'))
		setBookTo(moment(endDay).format('YYYY-MM-DD'))

		if(datePickerRef.current){
			datePickerRef.current.closeCalendar()
		}
  
		return `${moment(startDay).format('DD/MM/YYYY')} - ${moment(endDay).format('DD/MM/YYYY')}`
	  }
  }, [selectedDayRange])
  
  const customValue = (values) => {
    if (values && values[0] && values[1]) {
        const startDay = [values[0].year, values[0].month.number - 1, values[0].day]
        const endDay = [values[1].year, values[1].month.number - 1, values[1].day]
        const diff = moment.duration(moment(endDay).diff(moment(startDay))).asDays()
  
        console.log("DIFF", diff);
        setDiffDays(diff)

      if (diff < 1) {
        setDiffMessage(t('navbarBooking.errors.more'))
      } else {
        setDiffMessage(null)
      }

      setBookFrom(moment(startDay).format('YYYY-MM-DD'))
      setBookTo(moment(endDay).format('YYYY-MM-DD'))

      return `${moment(startDay).format('DD/MM/YYYY')} - ${moment(endDay).format('DD/MM/YYYY')}`
    }
  }

  const renderCustomInput = (props) => {
    const { ref } = props
    return (
      <div ref={ref} className={styles.overlapBooking__booking__input}>
        <label>{t('booking.modal.checkIn')} – {t('booking.modal.checkOut')}</label>
        <input
          readOnly
          // necessary
          placeholder={`${moment().format('DD/MM/YYYY')} - ${moment().add(1, 'day').format('DD/MM/YYYY')}`}
          value={customValue(selectedDayRange)}
          className="my-custom-input-class" // a styling class
        />
      </div>

    )
  }

  const onChangeParams = () => {
    const monthStarDate = _.get(selectedDayRange.from, 'month', 0).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    const monthEndDate = _.get(selectedDayRange.to, 'month', 0).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    const dayStarDate = _.get(selectedDayRange.from, 'day', 0).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    const dayEndDate = _.get(selectedDayRange.to, 'day', 0).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    const data = {
    //   startDate: `${_.get(selectedDayRange.from, 'year')}-${monthStarDate}-${dayStarDate}`,
    //   endDate: `${_.get(selectedDayRange.to, 'year')}-${monthEndDate}-${dayEndDate}`,
    startDate: selectedDayRange[0].format('YYYY-MM-DD'),
	 endDate: selectedDayRange[1].format('YYYY-MM-DD'),
      adults: totalAdults,
    }
    if (pathname === '/booking/') {
      navigate(`?startDate=${data.startDate}&endDate=${data.endDate}&adults=${data.adults}`)
    } else {
      navigate(`/booking/?startDate=${data.startDate}&endDate=${data.endDate}&adults=${data.adults}`)
    }
    onHandledOpenBooking()
  }

  
  return (
    <div className={styles.overlapBooking__container}>
      <div className={styles.overlapBooking__logo}>
        <Link to={`${getLangLink()}`}>
          <img
            style={{ width: '122px' }}
            src={logoOrange}
          />
        </Link>
      </div>
      <div style={{ cursor: 'pointer' }} className={styles.overlapBooking__back} onClick={onHandledOpenBooking}>
        <p><LeftArrow width="25px"/><span>{t('booking.buttons.back')}</span></p>
      </div>
      <div className={styles.overlapBooking__content}>

        <div className={styles.overlapBooking__box}>
          <div className={styles.overlapBooking__title}>
            <h2>{t('menu.bookWith')}</h2>
          </div>
          <div className={styles.overlapBooking__booking}>
            {/* <DatePicker
              value={selectedDayRange}
              onChange={setSelectedDayRange}
              renderInput={renderCustomInput}
              minimumDate={utils().getToday()}
              shouldHighlightWeekends
              calendarRangeStartClassName={styles.overlapBooking__booking__range}
              calendarRangeEndClassName={styles.overlapBooking__booking__range}
              colorPrimary="#D87021" // added this
              colorPrimaryLight='rgba(216, 112, 33, 0.2)'
              wrapperClassName={styles.overlapBooking__booking__calendar}
            /> */}
            <div style={{
				position: 'relative'
			}}>
				<label style={{
					position: 'relative',
					fontFamily: 'Sofia Pro Regular',
					fontSize: '12px',
					lineHeight: '12px',
				}}>{t('navbarBooking.dates')}</label>
			<DatePicker
				ref={datePickerRef}
                containerClassName="mobile-calendar"
				value={selectedDayRange}
				onChange={setSelectedDayRange}
				numberOfMonths={2}
				range
				minDate={new DateObject(new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Tokyo" })))}
				title="Check In – Check Out"
				calendarPosition="bottom-center"
				editable={false}
				monthYearSeparator=" "
				dateSeparator=" - "
				format="DD/MM/YYYY"
				style={{
					borderTop: '0',
					borderLeft: '0',
					borderRight: '0',
					borderBottom: '1px solid #000000',
					borderRadius: 0,
					backgroundColor: 'transparent',
					lineHeight: '27px',
					minHeight: '38px',
					fontFamily: 'Sofia Pro Regular',
					fontSize: '16px',
					minWidth: '210px',
                    width: '100%',
					userSelect: 'none',
				}}
				plugins={[
					<Toolbar 
						position="bottom" 
						sort={["close"]}
						names={{ close: "Done" }} />
				]}
			/> 
			
			<div onClick={() => onHandleCalendar(true)} style={{ position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}></div>
			</div>
            <div className={styles.overlapBooking__selectGroup}>
              <label>{t('navbarBooking.adults')}</label>
              <Select
                // menuIsOpen={true}
                isSearchable={false}
                onChange={(val) => setTotalAdults(val.value)}
                styles={customStyles}
                defaultValue={
                  !_.isEmpty(parametres)
                    ? { value: _.get(parametres, 'adults'), label: _.get(parametres, 'adults') }
                    : options[2]
                }
                options={options} />
            </div>
            {/* <div className={styles.overlapBooking__selectGroup}>
              <label>{t('navbarBooking.children')}</label>
              <Select
                isSearchable={false}
                onChange={(val) => setTotalChildren(val.value)}
                styles={customStyles}
                defaultValue={
                  !_.isEmpty(parametres)
                    ? { value: _.get(parametres, 'children'), label: _.get(parametres, 'children') }
                    : optionsChild[0]
                }
                options={optionsChild} />
            </div> */}
          </div>
          <div className={styles.overlapBooking__button}>
            <Button type="button" disabled={diffDays < 1 || totalAdults === 0} onClick={onChangeParams}>{t('menu.check')}</Button>
          </div>
          <div className={styles.overlapBooking__error}>
            {err && <p>{err}</p>}
            {diffMessage && <p>{diffMessage}</p>}
          </div>
        </div>

      </div>

	  {/* Calendar Popup */}
	  {
		isOpenCalendar && <>
			<div
				style={{
					backgroundColor: '#fff',
					display: 'flex',
					flexDirection: 'column',
					position: 'absolute',
					top: 0,
					left: 0,
					height: '100%',
					width: '100%'
				}}>
					<div className="calendar-wrap" style={{
						height: 'calc(100dvh - 68px)',
						// overflowY: 'auto'
						// height: '100%'
					}}>
						<span className="calendar-nights" style={{
							display: 'block',
							padding: '20px 20px 8px'
						}}>{t('navbarBooking.dates')} ({diffDays} {parseInt(diffDays) > 1 ? t('checkout.aside.nights') : t('checkout.aside.nights').replace('s', '')})</span>
						<img className="calendar-to" src={rightIcon} alt="Right Icon" />
						<DateRange
								onChange={item => setSelectedDayRanges(item.selection)}
								minDate={new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Tokyo" }))}
								showSelectionPreview={false}
								moveRangeOnFirstSelection={false}
								showMonthAndYearPickers={false}
								months={2}
								ranges={[selectedDayRanges]}
								shownDate={selectedDayRanges?.startDate}
								scroll={{ 
									enabled: true,
									monthHeight: 320, // 320 - 380
								}}
								rangeColors={["#D87021"]}
								// color="#D87021"
								showMonthArrow={false}
								dateDisplayFormat="E, MMM dd"
							/>
					</div>
					<div style={{
						backgroundColor: '#fff',
						boxShadow: '1px 0px 15px rgba(0, 0, 0, 0.15)',
						// marginTop: 'auto',
						padding: '14px 20px',
						position: 'relative',
						zIndex: '99'
					}}>
						<Button className="btn-dark" type="button" onClick={() => onHandleCalendar(false)}>Done</Button>
					</div>
				</div>
		</>
	  }

    </div>
  )
}

export default OverlapBooking
