// extracted by mini-css-extract-plugin
export var active = "header-styles-module--active--9687a";
export var arrow = "header-styles-module--arrow--b8ef2";
export var arrowDisabled = "header-styles-module--arrow--disabled--4b989";
export var arrowLeft = "header-styles-module--arrow--left--a36f5";
export var arrowRight = "header-styles-module--arrow--right--cbdea";
export var blink = "header-styles-module--blink--80b29";
export var bookingBtn = "header-styles-module--booking-btn--596b2";
export var bookingPageHeader = "header-styles-module--booking-page-header--c82b0";
export var checkout = "header-styles-module--checkout--1555e";
export var commentsSlider = "header-styles-module--commentsSlider--71bfc";
export var dot = "header-styles-module--dot--671a2";
export var dots = "header-styles-module--dots--497c8";
export var equallyBound = "header-styles-module--equallyBound--1aa8d";
export var fader = "header-styles-module--fader--4f941";
export var fader__slide = "header-styles-module--fader__slide--b2d43";
export var featuredSlider = "header-styles-module--featuredSlider--79dde";
export var globalWrapper = "header-styles-module--global-wrapper--2be7c";
export var headerIcons = "header-styles-module--header-icons--2ea09";
export var header__contacts = "header-styles-module--header__contacts--1cc59";
export var header__container = "header-styles-module--header__container--dce65";
export var header__content = "header-styles-module--header__content--98084";
export var header__content__booking = "header-styles-module--header__content__booking--b0853";
export var header__content__booking_btn = "header-styles-module--header__content__booking_btn--36824";
export var header__content__booking_call = "header-styles-module--header__content__booking_call--574cf";
export var header__content__booking_wrap = "header-styles-module--header__content__booking_wrap--aa8cb";
export var header__content__icon = "header-styles-module--header__content__icon--9ce33";
export var header__content__logo = "header-styles-module--header__content__logo--0cfeb";
export var header__content__menu = "header-styles-module--header__content__menu--d10b8";
export var header__wrapper = "header-styles-module--header__wrapper--4185d";
export var homepage = "header-styles-module--homepage--6ffd1";
export var homepageReviews = "header-styles-module--homepage-reviews--adbe9";
export var isOpenMenu = "header-styles-module--is-open-menu--c74d3";
export var keenSlider = "header-styles-module--keen-slider--ca015";
export var lineMove = "header-styles-module--lineMove--05450";
export var logo = "header-styles-module--logo--b554d";
export var navigationWrapper = "header-styles-module--navigation-wrapper--81b98";
export var scrollNav = "header-styles-module--scrollNav--2b34d";
export var scrollNavWrap = "header-styles-module--scrollNavWrap--482d5";
export var slickSlide = "header-styles-module--slick-slide--22487";
export var startTxtArea = "header-styles-module--startTxtArea--49c56";