exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-checkout-complete-js": () => import("./../../../src/pages/checkout-complete.js" /* webpackChunkName: "component---src-pages-checkout-complete-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-experiences-js": () => import("./../../../src/pages/experiences.js" /* webpackChunkName: "component---src-pages-experiences-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-suite-js": () => import("./../../../src/pages/suite.js" /* webpackChunkName: "component---src-pages-suite-js" */),
  "component---src-pages-suites-js": () => import("./../../../src/pages/suites.js" /* webpackChunkName: "component---src-pages-suites-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

