import _ from 'lodash'
import moment from 'moment'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'

const RoomContext = createContext('')

const RoomProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [rooms, setRooms] = useState([])
  const [maxGuess, setMaxGuess] = useState(0)
  const [params, setParams] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    adults: 1,
    children: 0,
  })
  /**
   * Ramon 07/04/2022,
   * make part of the logical, but looks like no childrens by now
   * @param {number} maxchildren A number to do something to.
   */
  const [maxchildren, setMaxChildren] = useState(0)
  const [loading, setLoading] = useState(true)

  const getMaxGuessByHotel = (roomsByHotel) => {
    const total = _.reduce(roomsByHotel, (n, room) => room.maxGuests + n, 0)
    setMaxGuess(_.range(total + 1))
  }

  const getRooms = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'getRooms',
      custom: true,
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
      } : {},
    }).then((resp) => {
      setRooms(resp)
      setLoading(false)
      getMaxGuessByHotel(resp)
    }).catch((error) => {
      console.log(error)
    })
  }, [i18n.language])

  useEffect(() => {
    getRooms()
  }, [])

  const values = {
    loading,
    rooms,
    maxGuess,
    maxchildren,
    setParams,
    params,
    getRooms,
  }

  return (
    <RoomContext.Provider value={values}>
      {children}
    </RoomContext.Provider>
  )
}

const useRooms = () => {
  const context = useContext(RoomContext)

  if (!context) { throw new Error('useLocale must be used within an RoomProvider') }

  return context
}

export { RoomProvider, useRooms }
