import React, { useEffect, useRef, useState } from "react";
import * as styles from "./map-styles.module.scss";
import imgContact from "../../assets/images/google-map-image.jpg";
import { useTranslation } from "react-i18next";

let gmapsLoaded = false;

const MapLocation = ({ menu, isHome }) => {	
	const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 600);
	const [mapLoaded, setMapLoaded] = useState(false);
	const mapRef = useRef(null);
	const { t } = useTranslation();

	async function initmap(menu) {		

		const mapOptions = {
			center: { lat: 35.0293601, lng: 135.77063 },
			zoom: 17,
			mapId: "23d3c673aa1d1cd3",
		};

		if (google && google.maps) {
			const { Map } = await google.maps.importLibrary("maps");
			const map = new Map(mapRef.current, mapOptions);
			const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

			const markerDiv = document.createElement("div");
			markerDiv.className = styles["map__dot"];

			if (!menu) { // Ensure this block only runs when menu is false
				const customMarker = document.createElement("div");
				customMarker.className = styles.map__card;
				customMarker.innerHTML = `
					<div style="position:relative">
					<img style="height:100%;width:100%;position:absolute;object-fit:cover;" src=${imgContact} alt="image hotel contact" />
					</div>
					<div>
					<p>${t("map.title")}</p>
					</div>
				`;

				const customMarkerParent = document.createElement("div");
				customMarkerParent.className = styles.map__parent;
				customMarkerParent.append(customMarker);
				customMarkerParent.append(markerDiv);

				const marker = new AdvancedMarkerElement({
					map,
					position: { lat: 35.0293601, lng: 135.77063 },
					content: customMarkerParent,
				});
			} else {
				const marker = new AdvancedMarkerElement({
					map,
					position: { lat: 35.0293601, lng: 135.77063 },
					content: markerDiv,
				});
			}
		}
	}

	useEffect(() => {
		if (!gmapsLoaded) {
			((g) => {
				var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window;
				b = b[c] || (b[c] = {});
				var d = b.maps || (b.maps = {}), r = new Set(), e = new URLSearchParams(), u = () =>
					h ||
					(h = new Promise(async (f, n) => {
						await (a = m.createElement("script"));
						e.set("libraries", [...r] + "");
						for (k in g)
							e.set(k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()), g[k]);
						e.set("callback", c + ".maps." + q);
						a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
						d[q] = f;
						a.onerror = () =>
							(h = n(Error(p + " could not load.")));
						a.nonce = m.querySelector("script[nonce]")?.nonce || "";
						m.head.append(a);
					}));
				d[l]
					? console.warn(p + " only loads once. Ignoring:", g)
					: (d[l] = (f, ...n) =>
							r.add(f) && u().then(() => d[l](f, ...n)));
			})({ key: "AIzaSyDGT6DXllh6z2OfWPO9A67LSO4YsQrfFS8", v: "weekly" });

			gmapsLoaded = true;
		} else {
			initmap(menu); // Ensure menu is passed to initmap
			setMapLoaded(true);
		}
	}, [menu]);

	useEffect(() => {
		const handleResize = () => {
			if (typeof window !== 'undefined') {
				setIsMobile(window.innerWidth <= 600);
			}
		};

		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, []);
	
	const mapMenuStyle = {
		marginTop: menu ? '24px' : '0px',		
		height: (menu ? (isMobile ? '200px' : '345px') : '810px'),
	};

	const mapBoxMenuStyle = {
		marginTop: menu ? '24px' : '0px',
		height: (menu ? (isMobile ? '200px' : '345px') : '810px'),		
		marginBottom: (menu ? (isMobile ? '60px' : '') : ''),
	};

	return (
		<div className={styles.map__container} style={mapMenuStyle}>
			<div className={`${styles.map__box} ${menu ? styles.map__boxMenu : ''}`} style={mapBoxMenuStyle} ref={mapRef} id="map"></div>
		</div>
	);
};

export default MapLocation;
