import React from 'react'
import IconFacebook from '../../assets/icons/social-media/facebook-blck.svg'
import IconLinkedin from '../../assets/icons/social-media/icon-linkedin.svg'
import IconYoutube from '../../assets/icons/social-media/youtube-blck.svg'
import IconInstagram from '../../assets/icons/social-media/black-instagram-logo.svg'
import IconTwitter from '../../assets/icons/social-media/icon-twitter.svg'
import IconWeChat from '../../assets/icons/social-media/icon-wechat.svg'

const getIcon = ({ name, color, size }) => {
  let Icon
  switch (name) {
    case 'facebook':
      Icon = <IconFacebook color={color || '#000'} width={size} height={size} />
      break
    case 'linkedin':
      Icon = <IconLinkedin color={color || '#000'} width={size} height={size} />
      break
    case 'youtube':
      Icon = <IconYoutube color={color || '#000'} width={size} height={size} />
      break
    case 'twitter':
      Icon = <IconTwitter color={color || '#000'} width={size} height={size} />
      break
    case 'wechat':
      Icon = <IconWeChat color={color || '#000'} width={size} height={size} />
      break
    case 'instagram':
      Icon = <IconInstagram color={color || '#000'} width={size} height={size} />
      break

    default:
      break
  }

  return Icon
}

export default getIcon
