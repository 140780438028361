/* eslint-disable prefer-destructuring */
import _ from 'lodash'
import axios from 'axios'

const GATSBY_API_WORDPRESS = process.env.GATSBY_API_URL
const GATSBY_API_WORDPRESS_CUSTOM = process.env.GATSBY_CUSTOM_API_URL

function makeRequest({
  headers,
  method,
  endPoint,
  // body,
  custom,
  params,
  data,
  urlString,
} = {}) {
  const urlBase = custom ? GATSBY_API_WORDPRESS_CUSTOM : GATSBY_API_WORDPRESS
  const url = !_.isEmpty(urlString) ? urlString : `${urlBase}/${endPoint}`
  return axios({
    method,
    url,
    data,
    params,
    headers,
  })
    .catch((error) => {
      console.error('error', error)
      throw error
    })
    .then((response) => response.data)
}

export default makeRequest
