import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'

const ExperiencesContext = createContext('')

const ExperiencesProvider = ({ children }) => {
  const { t, i18n } = useTranslation()
  const [experiences, setExperiences] = useState([])
  const [callToAction, setCallToAction] = useState({})
  const [loading, setLoading] = useState(false)

  const getExperiences = useCallback(async (language) => { // Receive language as an argument
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }

    // Use the 'language' argument in your API request
    makeRequest({
      headers,
      endPoint: 'pages',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
        slug: 'experience-kyoto',
      } : {
        slug: 'experience-kyoto',
      },
    }).then((resp) => {
      setExperiences(_.get(resp, '[0].acf.experiences'))
      setCallToAction(_.get(resp, '[0].acf.call_to_action_banner'))
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getExperiences(i18n.language) // Pass the current language to the hook
  }, [i18n.language])

  const values = {
    loading,
    experiences,
    callToAction,
  }

  return (
    <ExperiencesContext.Provider value={values}>
      {children}
    </ExperiencesContext.Provider>
  )
}

const useExperiences = () => {
  const context = useContext(ExperiencesContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { ExperiencesProvider, useExperiences }
