import React, { useState, useEffect, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as styles from './menu.module.scss';
import Button from '../Button';
import { getRoute } from '../../helpers';
import firstImage from '../../assets/images/first.webp';
import secondImage from '../../assets/images/second.png';
import map from '../../assets/images/map.png';
import MapLocation from "../../components/map/map";
import image1 from '../../assets/images/menu/Car.png';
import image2 from '../../assets/images/menu/Dining.png';
import image3 from '../../assets/images/menu/Jet.png';

const images = [image1, image2, image3];

const Menu = ({ isOpenMenu, onHandleMenu }) => {
  const { t, i18n } = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const menuRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000); // Change image every 3 seconds

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          onHandleMenu();
        }
      };

      if (isOpenMenu) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpenMenu, onHandleMenu]);  

  const languages = [
    {
      label: 'English',
      key: 'en',
      link: '/',
      active: i18n.language === 'en',
    },
    {
      label: '日本',
      key: 'ja',
      link: '/ja/',
      active: i18n.language === 'ja',
    },
    {
      label: '繁體',
      key: 'zh-hant',
      link: '/zh-hant/',
      active: i18n.language === 'zh-hant',
    },
  ];

  const onLanguageChange = (language) => {
    let link;
    if (language === 'ja') {
      link = '/ja/';
    } else if (language === 'zh-hant') {
      link = '/zh-hant/';
    } else {
      link = '/';
    }

    i18n.changeLanguage(language);
    navigate(link, { replace: true });
  };

  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if (activeLanguage) {
      switch (activeLanguage) {
        case 'ja':
          link = '/ja/';
          break;
        case 'zh-hant':
          link = '/zh-hant/';
          break;
        default:
          link = '/';
      }
    }
    return link;
  };

  const onGoBooking = () => {
    navigate(`${getLangLink()}booking/?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().add(2, 'day').format('YYYY-MM-DD')}&adults=2&children=0`);
    onHandleMenu();
  };

  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth <= 600);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const firstImageStyle = {
    marginBottom: isMobile ? '4px' : '8px',
  };

  const secondImageStyle = {
    marginBottom: isMobile ? '24px' : '40px',
  };

  const h3Style = {
    marginBottom: isMobile ? '8px' : '16px',
  };

  const mailStyle = {
    marginBottom: isMobile ? '16px' : '40px',
  };

  // Fungsi untuk menangani klik pada right__container
  const handleRightContainerClick = () => {
    console.log('Right container clicked');
    onHandleMenu();
  };

  return (
    <div
      ref={menuRef}
      className={isOpenMenu ? `${styles.menu__container} ${styles.menu__container__open}` : `${styles.menu__container}`}
    >
      <div className={styles.menu__left__container}>
        <div className={styles.menu__left__container__one}>
          <h1 className={styles.menu__logo}>Kamo <span>Residences</span></h1>
          <div className={styles.menu__links__block}>
            <Link onClick={onHandleMenu} to={getRoute('/experiences/', i18n.language)} className={styles.menu__link}>
              <div className={styles.menu__link__item}>
                <span>{t('menu.menuSlide.experience')}</span>
              </div>
            </Link>
            <Link onClick={onHandleMenu} to={getRoute('/suites/', i18n.language)} className={styles.menu__link}>
              <div className={styles.menu__link__item}>
                <span>{t('menu.menuSlide.suites')}</span>
              </div>
            </Link>
            <Link onClick={onHandleMenu} to={getRoute('/suites/', i18n.language)} className={styles.menu__link}>
              <div className={styles.menu__link__item}>                
                <span>{t('menu.menuSlide.concierge')}</span>
                <img
                  src={images[currentImageIndex]}
                  className={`${styles.blinkingImage} ${styles.active}`}
                  alt="Changing Image"
                />
              </div>
            </Link>
            <Link style={{ marginBottom: '20px' }} onClick={onHandleMenu} to={getRoute('/contact/', i18n.language)} className={styles.menu__link}>
              <div className={styles.menu__link__item}>
                <span>{t('menu.menuSlide.contact')}</span>
              </div>
            </Link>
          </div>

          <h3 className={styles.menu__text__body} style={{ marginTop:'15px', marginBottom:'0px'}}>
            <a href={`${getLangLink()}terms-and-conditions/`}>Terms & Conditions <br /> </a>
            </h3>
            
          <h3 className={styles.menu__text__body} style={{marginBottom:'30px'}}>
            <a href={`${getLangLink()}cancellation-policy/`}>Cancellation Policy</a></h3>
          <h4 className={styles.menu__text__tag}>Address</h4>
          <h4 className={styles.menu__text__body} style={{ maxWidth: '60%' }}>447-6 Kajiicho Kamigyo Ward Kyoto 602-0841 JAPAN</h4>

          <h4 className={styles.menu__text__tag}>Phone Number</h4>          
          <h5 className={styles.menu__text__body}>
            <a style={{color:'black'}} href="tel:+817043197708">+81 70 4319 7708</a>
          </h5>
          <h2 className={styles.menu__text__body2}>Reflections Group <span> <br /> & Sync Sloan Co. Ltd.</span></h2>
          <h4 style={{ marginTop: '7px' }} className={styles.menu__text__tag}>Corporate Site</h4>
          <div className={styles.menu__languages}>
            {languages.map((lng) => (
              <Link
                key={lng.key}
                to={lng.link}
                style={{
                  fontWeight: lng.active ? 'bold' : 'normal',
                  color: lng.active ? '#D87021' : '#595756',
                  textDecoration: lng.active ? 'underline' : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onHandleMenu();
                  i18n.changeLanguage(lng.key);
                  onLanguageChange(lng.key);
                }}
              >
                {lng.label}
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.menu__left__container__two}>
          <h1 className={styles.menu__logo__fixed}>R | <span>GLOBAL</span></h1>
          <h5 className={styles.menu__text__body}>Kyoto, JAPAN</h5>
          <ul>
            <li>MIYA <span>TOWN HOUSE</span></li>
            <li>SLOAN SUITE</li>
          </ul>
          <h5 className={styles.menu__text__body}>Bodrum, TURKEY</h5>
          <ul>
            <li>MINA <span>SUMMIT</span></li>
          </ul>
          <h3 className={styles.menu__text__body} style={{ marginTop: '40px', marginBottom: '16px' }}> | <span>PROMOTIONS & PACKAGES</span></h3>
          <p className={styles.menu__text__desc}>Luxury Airport Shuttle + Romantic Champagne Greeting. 
            <span>          
              <a style={{color:'#000000'}} href={getRoute('/contact/', i18n.language)}> Message Us.</a>            
              </span>            
            </p>

          <img style={firstImageStyle} className={styles.menu__image} src={firstImage} alt=".." />
          <img style={secondImageStyle} className={styles.menu__image} src={secondImage} alt=".." />

          <h3 className={styles.menu__text__body} style={h3Style}> | <span>GROUP RESERVATION</span></h3>
          <p className={styles.menu__text__desc2}>For customized group reservation please contact our reservations support.</p>
          <p style={mailStyle}><a className={styles.menu__text__mail} href='mailto:info@reflections.group'>info@reflections.group</a></p>
          

          <h3 className={styles.menu__text__body} style={h3Style}> | <span>LOCATION LANDMARK</span></h3>          
          <div className={styles.menu__map}>
           <MapLocation isHome={true} menu={isOpenMenu}/>
          </div>
          
        </div>
      </div>
      <div
        className={styles.menu__right__container}
        onClick={handleRightContainerClick} // Menambahkan event handler
      >
        {/* Konten untuk right__container */}
      </div>
    </div>
  );
};

export default Menu;
