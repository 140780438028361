// extracted by mini-css-extract-plugin
export var active = "menu-module--active--dcbec";
export var arrow = "menu-module--arrow--0b970";
export var arrowDisabled = "menu-module--arrow--disabled--fc301";
export var arrowLeft = "menu-module--arrow--left--c6f65";
export var arrowRight = "menu-module--arrow--right--c7f38";
export var blink = "menu-module--blink--429db";
export var blinkingImage = "menu-module--blinkingImage--d33e1";
export var bookingBtn = "menu-module--booking-btn--96577";
export var bookingPageHeader = "menu-module--booking-page-header--4ca6f";
export var checkout = "menu-module--checkout--c1537";
export var commentsSlider = "menu-module--commentsSlider--28302";
export var dot = "menu-module--dot--b276a";
export var dots = "menu-module--dots--b0ef6";
export var equallyBound = "menu-module--equallyBound--fd17f";
export var fader = "menu-module--fader--ccaa7";
export var fader__slide = "menu-module--fader__slide--556e2";
export var featuredSlider = "menu-module--featuredSlider--377b7";
export var globalWrapper = "menu-module--global-wrapper--a1666";
export var headerIcons = "menu-module--header-icons--525c3";
export var homepage = "menu-module--homepage--cb8ba";
export var homepageReviews = "menu-module--homepage-reviews--c6989";
export var isOpenMenu = "menu-module--is-open-menu--c2357";
export var keenSlider = "menu-module--keen-slider--bf381";
export var lineMove = "menu-module--lineMove--f4e0d";
export var logo = "menu-module--logo--31ae4";
export var menu__booking = "menu-module--menu__booking--83692";
export var menu__container = "menu-module--menu__container--6ac99";
export var menu__container__open = "menu-module--menu__container__open--fb4b0";
export var menu__image = "menu-module--menu__image--f3138";
export var menu__image__block = "menu-module--menu__image__block--d9057";
export var menu__languages = "menu-module--menu__languages--bb9ae";
export var menu__left__container = "menu-module--menu__left__container--d6773";
export var menu__left__container__one = "menu-module--menu__left__container__one--42f79";
export var menu__left__container__two = "menu-module--menu__left__container__two--437f9";
export var menu__link = "menu-module--menu__link--b5c03";
export var menu__link__item = "menu-module--menu__link__item--8f4ce";
export var menu__links__block = "menu-module--menu__links__block--3c6f6";
export var menu__logo = "menu-module--menu__logo--d9f0d";
export var menu__logo__fixed = "menu-module--menu__logo__fixed--c1947";
export var menu__map = "menu-module--menu__map--69612";
export var menu__mask__image = "menu-module--menu__mask__image--ff278";
export var menu__right__container = "menu-module--menu__right__container--a3d9c";
export var menu__text__body = "menu-module--menu__text__body--ada6a";
export var menu__text__body2 = "menu-module--menu__text__body2--c0e4c";
export var menu__text__desc = "menu-module--menu__text__desc--39fa4";
export var menu__text__desc2 = "menu-module--menu__text__desc2--7e3ff";
export var menu__text__mail = "menu-module--menu__text__mail--31ebc";
export var menu__text__tag = "menu-module--menu__text__tag--9d926";
export var navigationWrapper = "menu-module--navigation-wrapper--3953e";
export var scrollNav = "menu-module--scrollNav--155f0";
export var scrollNavWrap = "menu-module--scrollNavWrap--76d1b";
export var slickSlide = "menu-module--slick-slide--70edf";
export var startTxtArea = "menu-module--startTxtArea--e7a6c";