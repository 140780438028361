import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'

const EventsContext = createContext('')

const EventsProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  const getEvents = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'event',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
      } : {},
    }).then((resp) => {
      setEvents(resp)
      setLoading(false)
    })
  }, [i18n.language])

  useEffect(() => {
    getEvents()
  }, [])
  const values = {
    loading,
    events,
  }

  return (
    <EventsContext.Provider value={values}>
      {children}
    </EventsContext.Provider>
  )
}

const useEvents = () => {
  const context = useContext(EventsContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { EventsProvider, useEvents }
