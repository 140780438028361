import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import getIcon from '../../helpers/get-icon'

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
`
const LinkSocialMedia = styled.a`
    position: relative;
    width: 18px;
    height: 18px:
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width:  ${(props) => `${props.width}px`};
    height:  ${(props) => `${props.height}px`};
    transition: all 0.5s;
    &:hover {
      svg {
        color: ${(props) => `${props.hoverColor}`}
      }
    }
  
    svg {
      position: absolute;
      top: 40%;
      left: 40%;
      transform: translate(-50%, -50%);
      display: block;
      height: 20px;
      width: 20px;
    }
`
const SocialMedia = ({ data }) => (
  <SocialMediaWrapper className="social-media">
    {_.map(data, (sm) => <LinkSocialMedia
      key={sm.link}
      height={`${sm.size}`}
      width={`${sm.size}`}
      hoverColor={sm.hoverColor}
      target="_blank"
      href={sm.link}>{
        getIcon({
          name: sm.name,
          color: sm.color,
          size: sm.size,
        })
      }</LinkSocialMedia>)}
  </SocialMediaWrapper>
)

export default SocialMedia
