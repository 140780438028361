// extracted by mini-css-extract-plugin
export var active = "footer-styles-module--active--3ac2f";
export var arrow = "footer-styles-module--arrow--e9dc2";
export var arrowDisabled = "footer-styles-module--arrow--disabled--1431e";
export var arrowLeft = "footer-styles-module--arrow--left--3992b";
export var arrowRight = "footer-styles-module--arrow--right--c8684";
export var blink = "footer-styles-module--blink--3d2d8";
export var block = "footer-styles-module--block--9aca4";
export var bookingBtn = "footer-styles-module--booking-btn--bbd1a";
export var bookingPageHeader = "footer-styles-module--booking-page-header--ff949";
export var checkout = "footer-styles-module--checkout--bf8be";
export var children__block = "footer-styles-module--children__block--630f7";
export var commentsSlider = "footer-styles-module--commentsSlider--a7268";
export var dot = "footer-styles-module--dot--9a193";
export var dotLink = "footer-styles-module--dotLink--4282d";
export var dots = "footer-styles-module--dots--c5a6b";
export var equallyBound = "footer-styles-module--equallyBound--1c9b0";
export var fader = "footer-styles-module--fader--d23a6";
export var fader__slide = "footer-styles-module--fader__slide--290d0";
export var featuredSlider = "footer-styles-module--featuredSlider--c6ef9";
export var footer__box = "footer-styles-module--footer__box--2c1a5";
export var footer__box__bottom = "footer-styles-module--footer__box__bottom--0190f";
export var footer__box__bottom__languages = "footer-styles-module--footer__box__bottom__languages--de7a8";
export var footer__box__bottom__links = "footer-styles-module--footer__box__bottom__links--08d63";
export var footer__box__top = "footer-styles-module--footer__box__top--bd767";
export var footer__box__top__children = "footer-styles-module--footer__box__top__children--c0fcc";
export var footer__container = "footer-styles-module--footer__container--54a06";
export var footer__logos = "footer-styles-module--footer__logos--3272d";
export var footer__top__children = "footer-styles-module--footer__top__children--239bf";
export var globalWrapper = "footer-styles-module--global-wrapper--f5dc4";
export var headerIcons = "footer-styles-module--header-icons--3c68a";
export var homepage = "footer-styles-module--homepage--104e8";
export var homepageReviews = "footer-styles-module--homepage-reviews--e2658";
export var isOpenMenu = "footer-styles-module--is-open-menu--3ada2";
export var keenSlider = "footer-styles-module--keen-slider--64a9f";
export var lineMove = "footer-styles-module--lineMove--02875";
export var link = "footer-styles-module--link--34f49";
export var location = "footer-styles-module--location--8726c";
export var logo = "footer-styles-module--logo--cebb5";
export var middle = "footer-styles-module--middle--fad39";
export var navigationWrapper = "footer-styles-module--navigation-wrapper--d841a";
export var scrollNav = "footer-styles-module--scrollNav--0be82";
export var scrollNavWrap = "footer-styles-module--scrollNavWrap--caed5";
export var slickSlide = "footer-styles-module--slick-slide--13fc0";
export var startTxtArea = "footer-styles-module--startTxtArea--fff6e";