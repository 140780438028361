import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import { MenuProvider } from '../hooks/use-menu'
import { CalendarProvider } from '../hooks/use-calendar'
import { ExperiencesProvider } from '../hooks/use-experiences'
import { BookingProvider } from '../hooks/use-booking'
import { RoomProvider } from '../hooks/use-rooms'
import { FooterProvider } from '../hooks/use-footer'
import { CtaProvider } from '../hooks/use-cta'
import { EventsProvider } from '../hooks/use-events'
import i18next from '../i18n/config'
import { GlobalStyle, theme } from '../theme'

const stripe = loadStripe(process.env.GATSBY_STRIPE_KEY)
// const options = {
//   // passing the client secret obtained from the server
//   clientSecret: '{{sk_test_51KTM8BLWwoo3Yg7DMzNkZhNvGG4GV4S0YzYrfpCCowEt7Ek2UDh0cgyElnFL1WMvEjcjMyKGUDxDUfGweTwLXGIs00mGuHz3W6}}',
// }

const wrapRootElement = (props) => (
  <I18nextProvider i18n={i18next}>
    <ThemeProvider theme={theme}>
      <Elements stripe={stripe} >
        <RoomProvider >
          <BookingProvider>
            <MenuProvider>
				<CalendarProvider>
					<FooterProvider>
						<EventsProvider>
						<ExperiencesProvider>
							<CtaProvider>
							<GlobalStyle />
							{props.element}
							</CtaProvider>
						</ExperiencesProvider>
						</EventsProvider>
					</FooterProvider>
				</CalendarProvider>
            </MenuProvider>
          </BookingProvider>
        </RoomProvider>
      </Elements>
    </ThemeProvider>
  </I18nextProvider>
)

export default wrapRootElement
