import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import sofia from '../assets/fonts/sofia-pro-regular-az.woff2'
import sofiaLight from '../assets/fonts/sofiapro-light.woff2'
import JustSignature from '../assets/fonts/Just-Signature.woff'

export const theme = {
  colors: {
    primary: '#D87021',
    secondary: '#595756',
    hover: '#C15F14',
    white: '#ffffff',
    black: '#000000',
    westar: '#E0D9D1',
    whiteSmoke: '#F4F0EB',
  },
  font: {
    sofia: "'Sofia Pro Regular', sans-serif",
    justSignature: "'Just Signature Regular'",
    ivarDisplayHydro: 'IvarDisplayHydro',
  },
  fontSize: {
    xs: '0.75rem', // 12px
    xss: '0.8125rem', // 13px
    sm: '0.875rem', // 14px
    base: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '2xll': '1.625rem', // 26px
    '3xl': '1.75rem', // 28px
    '4xl': '2.125rem', // 34px
    '5xl': '3rem', // 48px
    '7xl': '3.6rem', // 56px
    '8xl': '5rem', // 80px
  },
  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  lineHeight: {
    none: 'none',
    xs: '0.75rem', // 12px
    xss: '0.8125rem', // 13px
    sm: '0.875rem', // 14px
    base: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.75rem', // 28px
    '4xl': '2.125rem', // 34px
    '4xll': '2.375rem', // 38px
    '5xl': '2.625rem', // 42px
    '6xl': '3rem', // 48px
    '7xl': '3.6rem', // 56px
    '8xl': '5rem', // 80px

  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
    font-display: fallback !important;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 16px;
  }
  body {
    background-color: #F6F6F6;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'Sofia Pro Regular';
    src: url(${sofia}) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: fallback !important;
  }

  @font-face {
    font-family: 'Sofia Pro Light';
    src: url(${sofiaLight}) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: fallback !important;
  }

  @font-face {
    font-family: 'Just Signature Regular';
    src: url(${JustSignature}) format('woff');
    // font-weight: 400;
    // font-style: normal;
    // font-display: fallback !important;
  }

  @font-face {
    font-family: IvarDisplayHydro;
    src: url(/fonts/IvarDisplayHydro-Regular.eot);
    src: url(/fonts/IvarDisplayHydro-Regular.eot?#iefix) format("embedded-opentype"), 
    url(/fonts/IvarDisplayHydro-Regular.woff2) format("woff2"), 
    url(/fonts/IvarDisplayHydro-Regular.woff2) format("woff2"), 
    url(/fonts/IvarDisplayHydro-Regular.woff) format("woff"), 
    url(/fonts/IvarDisplayHydro-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap
  }

  .max-with-website {
    max-width: 1656px;
  }

  .overlay {
    overflow: hidden;
  }
  .is-open-menu {
    overflow: hidden;
    
    
  }

  .rich-text {
    font-family: ${theme.font.sofia};
    line-height: 24px;
	text-align: justify;
	& > br{
		display: none;
	}
    h1, h2, h3, h4 {
      font-family: ${theme.font.ivarDisplayHydro};
      font-weight: 400;
      margin-bottom: 8px;
      color: ${theme.colors.black};
    }
    h2 {
      font-size: 22px;
      line-height: 30px;
    }
    h3 {
      font-size: 22px;
      line-height: 30px;
    }
    p {
      color: ${theme.colors.secondary};
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      font-family: ${theme.font.sofia};
      margin-bottom: 32px;

    }
    ul {
      padding-left: 40px;
      margin: 20px 0;
      li {
        position: relative;
        color: ${theme.colors.secondary};
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        font-family: ${theme.font.sofia};
        &:before {
          position: absolute;
          content: '';
          top: 40%;
          left: -12px;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: ${theme.colors.primary};
          
        }
      }
    }
    .wp-block-quote {
      margin: 20px 0 20px 40px;
      padding-left: 20px;
      border-left: 1px solid #7B7B7B;
      p {
        color: ${theme.colors.primary};
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        font-family: ${theme.font.sofia};
      }
      cite {
        color: ${theme.colors.secondary};
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
        font-family: ${theme.font.sofia};
      }
    }

    
    a {
      color: ${theme.colors.primary};
    }
  }
  .anchor-footer {
    margin-bottom: 15px;
  }
`
