// extracted by mini-css-extract-plugin
export var active = "overlap-book-styles-module--active--f3e4d";
export var arrow = "overlap-book-styles-module--arrow--bfaca";
export var arrowDisabled = "overlap-book-styles-module--arrow--disabled--877ed";
export var arrowLeft = "overlap-book-styles-module--arrow--left--af363";
export var arrowRight = "overlap-book-styles-module--arrow--right--39755";
export var blink = "overlap-book-styles-module--blink--64760";
export var bookingBtn = "overlap-book-styles-module--booking-btn--e0c32";
export var bookingPageHeader = "overlap-book-styles-module--booking-page-header--ef66e";
export var checkout = "overlap-book-styles-module--checkout--eccfd";
export var commentsSlider = "overlap-book-styles-module--commentsSlider--32b98";
export var dot = "overlap-book-styles-module--dot--3ba73";
export var dots = "overlap-book-styles-module--dots--71057";
export var equallyBound = "overlap-book-styles-module--equallyBound--6fa98";
export var fader = "overlap-book-styles-module--fader--5e083";
export var fader__slide = "overlap-book-styles-module--fader__slide--3cdc0";
export var featuredSlider = "overlap-book-styles-module--featuredSlider--f45a0";
export var globalWrapper = "overlap-book-styles-module--global-wrapper--fe45e";
export var headerIcons = "overlap-book-styles-module--header-icons--54226";
export var homepage = "overlap-book-styles-module--homepage--132fe";
export var homepageReviews = "overlap-book-styles-module--homepage-reviews--1cbbf";
export var isOpenMenu = "overlap-book-styles-module--is-open-menu--b5560";
export var keenSlider = "overlap-book-styles-module--keen-slider--16639";
export var lineMove = "overlap-book-styles-module--lineMove--ebd7f";
export var logo = "overlap-book-styles-module--logo--dc677";
export var navigationWrapper = "overlap-book-styles-module--navigation-wrapper--bccea";
export var overlapBooking__back = "overlap-book-styles-module--overlapBooking__back--d0a4f";
export var overlapBooking__booking = "overlap-book-styles-module--overlapBooking__booking--5c739";
export var overlapBooking__booking__calendar = "overlap-book-styles-module--overlapBooking__booking__calendar--6ae15";
export var overlapBooking__booking__input = "overlap-book-styles-module--overlapBooking__booking__input--62463";
export var overlapBooking__booking__range = "overlap-book-styles-module--overlapBooking__booking__range--cf5bc";
export var overlapBooking__box = "overlap-book-styles-module--overlapBooking__box--d8c1d";
export var overlapBooking__button = "overlap-book-styles-module--overlapBooking__button--2ff7b";
export var overlapBooking__container = "overlap-book-styles-module--overlapBooking__container--e0c4d";
export var overlapBooking__error = "overlap-book-styles-module--overlapBooking__error--24056";
export var overlapBooking__logo = "overlap-book-styles-module--overlapBooking__logo--a34dd";
export var overlapBooking__selectGroup = "overlap-book-styles-module--overlapBooking__selectGroup--499e4";
export var overlapBooking__title = "overlap-book-styles-module--overlapBooking__title--88937";
export var scrollNav = "overlap-book-styles-module--scrollNav--5e990";
export var scrollNavWrap = "overlap-book-styles-module--scrollNavWrap--76798";
export var slickSlide = "overlap-book-styles-module--slick-slide--ca5c9";
export var startTxtArea = "overlap-book-styles-module--startTxtArea--30dea";