import ReactGA from 'react-ga'
import _ from 'lodash'

const React = require('react')

export { wrapRootElement } from './gatsby-ssr'

export const onRouteUpdate = (location) => {
  // if (location.hash) {
  //   setTimeout(() => {
  //     document.querySelector(`${location.hash}`).scrollIntoView()
  //   }, 0)
  // }
  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    if (pagePath) ReactGA.pageview(pagePath)
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  setTimeout(sendPageView, 100)
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    '/contact/',
    '/event/',
    'experiences/',
    '/booking/',
  ]
  // if the new route is part of the list above, scroll to top (0, 0)

  if (_.some(scrollToTopRoutes, (route) => _.includes(pathname, route))) {
    window.scrollTo(0, 0)
  }

  return false
}
