import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
} from 'react'

const MenuContext = createContext('')

const MenuProvider = ({ children }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isOpenBookingMenu, setIsOpenBookingMenu] = useState(false)
  const onHandleMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }
  const handledOpenBooking = () => {
    setIsOpenBookingMenu(!isOpenBookingMenu)
  }

  const values = {
    onHandleMenu,
    isOpenMenu,
    handledOpenBooking,
    isOpenBookingMenu,
  }

  return (
    <MenuContext.Provider value={values}>
      {children}
    </MenuContext.Provider>
  )
}

const useMenu = () => {
  const context = useContext(MenuContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { MenuProvider, useMenu }
