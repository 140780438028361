import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'

const CtaContext = createContext('')

const CtaProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [cta, setCta] = useState([])
  const [loading, setLoading] = useState(false)

  const getCta = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'cta',
      custom: true,
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
      } : {},
    }).then((resp) => {
      setCta(resp.cta)
      setLoading(false)
    })
  }, [i18n.language])

  useEffect(() => {
    getCta()
  }, [i18n.language])
  const values = {
    loading,
    cta,
  }

  return (
    <CtaContext.Provider value={values}>
      {children}
    </CtaContext.Provider>
  )
}

const useCta = () => {
  const context = useContext(CtaContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { CtaProvider, useCta }
