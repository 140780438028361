import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'

const FooterContext = createContext('')

const FooterProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [footer, setFooter] = useState([])
  const [loading, setLoading] = useState(false)

  const getFooter = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'footer',
      custom: true,
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
      } : {},
    }).then((resp) => {
      setFooter(resp)
      setLoading(false)
    })
  }, [i18n.language])

  useEffect(() => {
    getFooter()
  }, [])
  const values = {
    loading,
    footer,
  }

  return (
    <FooterContext.Provider value={values}>
      {children}
    </FooterContext.Provider>
  )
}

const useFooter = () => {
  const context = useContext(FooterContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { FooterProvider, useFooter }
