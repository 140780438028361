import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link, navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useFooter } from '../../hooks/use-footer';
import { getRoute } from '../../helpers';
import SocialMedia from '../social-media';
import * as styles from './footer-styles.module.scss';
import logoMiya from '../../assets/logo/reflections_logo_miya.png';
import logoToki from '../../assets/logo/reflections_logo_toki.png';
import Button from '../Button'; // Import Button component if needed for language switching

const Footer = ({ onHandleMenu }) => {
  const fullYear = new Date().getFullYear();
  const { t, i18n } = useTranslation();
  const { footer } = useFooter();
  const objs = _.reduce(_.get(footer, 'social_icons.links'), (result, value, key) => {
    (result).push({
      name: key,
      link: value,
      size: 28,
      color: '#000000',
      hoverColor: '#D87021',
    });

    return result;
  }, []);

  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if(activeLanguage) {
        switch(activeLanguage){
            case 'ja':
                link = '/ja/';
                break;
            case 'zh-hant':
                link = '/zh-hant/';
                break;
            default: link = '/';
        }
    }
    return link;
  }

  const languages = [
    {
      label: 'English',
      key: 'en',
      link: '/',
    },
    {
      label: '日本',
      key: 'ja',
      link: '/ja/',
    },
    {
      label: '繁體',
      key: 'zh-hant',
      link: '/zh-hant/',
    },
  ];

  // Add an event handler for language switching
  const handleLanguageSwitch = (langKey) => {
    i18n.changeLanguage(langKey);
    // If you want to close the menu when switching languages, you can call onHandleMenu here
    // onHandleMenu();
  };

  return (

    <div className={styles.footer__container}>

      <div className={styles.footer__box}>
        <div className={styles.footer__box__top}>
          <div className={styles.footer__box__top__children}>
            <h5>{t('footer.up.title')}</h5>
            <p>{t('footer.up.aboutDesc')}</p>
          </div>
          <div className={styles.footer__box__top__children}>
            <h5>{t('footer.up.about')}</h5>
            <ul>
              <li className={styles.link}>
                <Link to={getRoute('/experiences/', i18n.language)}>
                  <span>{t('footer.up.aboutChildren.experience')}</span>
                </Link>
              </li>
              <li className={styles.link}>
                <Link to={getRoute('/suites/', i18n.language)}>
                  <span>{t('footer.up.aboutChildren.suites')}</span>
                </Link>
              </li>
              <li className={styles.link}>
                <Link to={getRoute('/contact/', i18n.language)}>
                  <span>{t('footer.up.aboutChildren.contact')}</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className={`${styles.footer__box__top__children} ${styles.block}`}>
            <div className={styles.children__block}>
              <h5>{t('footer.up.connect')}</h5>
              <SocialMedia data={objs}/>
            </div>
            <div className={styles.children__block}>
              <h5>{t('footer.up.others')}</h5>
              <div className={styles.footer__logos}>
                <a>
                  <img alt="Toki Logo" src={logoToki} />
                </a>
                <a >
                  <img alt="Miya Logo" src={logoMiya} />
                </a>

              </div>
            </div>

          </div>

        </div>
        <div className={`${styles.footer__box__top} ${styles.middle}`}>
          <div
            className={`${styles.footer__box__top__children} ${styles.location}`}>
            <h5>{t('footer.down.location')}</h5>
            <p>{_.get(footer, 'contacts.location')}</p>
          </div>
          <div className={`${styles.footer__box__top__children} ${styles.block}`}>
            <div className={styles.children__block}>
              <h5>{t('footer.down.whatsapp')}</h5>
              <a href = {`https://wa.me/${_.get(footer, 'contacts.whatsapp')}`}>
                <p>+{_.get(footer, 'contacts.whatsapp')}</p>
              </a>

            </div>
            <div className={styles.children__block}>
              <h5>{t('footer.down.phone')}</h5>
              <p>
                <a href = {`tel: ${_.get(footer, 'contacts.phone')}`}>
                  {_.get(footer, 'contacts.phone')}
                </a>
              </p>
            </div>
          </div>
          <div className={styles.footer__box__top__children}>
            <h5>{t('footer.down.email')}</h5>
            <p><a href = {`mailto: ${_.get(footer, 'contacts.email')}`}>
              {_.get(footer, 'contacts.email')}
            </a></p>
          </div>

        </div>

        <div className={styles.footer__box__bottom}>
        <div className={styles.footer__box__bottom__links}>
          <div>
            <span>© {fullYear} Reflections Group</span>
            <Link className={styles.dotLink} to={`${getLangLink()}cancellation-policy/`}><span>{t('footer.links.cancellation')}</span></Link>
            <Link className={styles.dotLink} to={`${getLangLink()}privacy-policy/`}><span>{t('footer.links.privacy')}</span></Link>
            <Link className={styles.dotLink} to={`${getLangLink()}terms-and-conditions/`}><span>{t('footer.links.terms')}</span></Link>
            <a href="https://wecreate.com.hk/web-design-hong-kong/" className={styles.dotLink} title="Web Design Hong Kong" target="_blank" style={{ display: 'inline-flex', lineHeight: 1, verticalAlign: 'text-bottom' }}>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 16 16" style={{ enableBackground: 'new 0 0 16 16' }} xmlSpace="preserve" height="16px" width="16px" fill="#595756">
                    <g id="XMLID_1_">
                        <polygon id="XMLID_13_" points="8.39,2.61 5.59,2.61 10.56,13.66 13.36,13.66"/>
                        <path id="XMLID_12_" d="M14.36,2.61c-0.91,0-1.64,0.74-1.64,1.64c0,0.91,0.74,1.64,1.64,1.64S16,5.15,16,4.25
                            C16,3.34,15.26,2.61,14.36,2.61z"/>
                        <polygon id="XMLID_11_" points="2.8,2.61 0,2.61 4.97,13.66 7.76,13.66   "/>
                    </g>
                </svg>
            </a>
          </div>
        </div>
        <div className={styles.footer__box__bottom__languages}>
            {languages.map((lng) => (
              <Link
                key={lng.label}
                to={lng.link}
                onClick={() => handleLanguageSwitch(lng.key)} // Call the language switch function
              >
                {lng.label}
              </Link>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Footer
