import _ from 'lodash'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import React, { useState, useEffect } from 'react'
import CookieConsent, { resetCookieConsentValue } from 'react-cookie-consent'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { withTrans } from '../../i18n/withTrans'
import Footer from '../Footer'
import HeaderDesktop from '../Header/HeaderDestop'
import OverlapBooking from '../overlap-booking'
import { useMenu } from '../../hooks/use-menu'
import { useCalendar } from '../../hooks/use-calendar'
import Scroll from '../scrollToTop'
import Menu from '../menu'
import * as styles from './layout-styles.module.scss'
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({
  children,
  location,
  t,
  i18n,
}) => {
  const {
    onHandleMenu,
    isOpenMenu, 
    isOpenBookingMenu,
    handledOpenBooking,
  } = useMenu()
  const {
    onHandleCalendar,
    isOpenCalendar
  } = useCalendar()
  const breakpoints = useBreakpoint()
  const [showToTop, setShowToTop] = useState(true)
  const pathsPaddings = ['/', '/404/', '/suites/', '/ja/', '/ja/404/', '/ja/suites/', '/zh-hant/', '/zh-hant/404/', '/zh-hant/suites/']
  const homepage = ['/', '/ja/', '/zh-hant/'];
  const [isHomepage, setIsHomepage] = useState(true)
  const pathsBooking = [
    '/booking/',
    '/zh-hant/booking/',
    '/ja/booking/',
    '/checkout/',
    '/zh-hant/checkout/',
    '/ja/checkout/',
    '/checkout-complete/',
    '/zh-hant/checkout-complete/',
    '/ja/checkout-complete/',
  ]

  const pathsBooking2 = [
    '/booking/',
    '/zh-hant/booking/',
    '/ja/booking/',
    '/checkout/',
    '/zh-hant/checkout/',
    '/ja/checkout/',
    '/experiences/',
    '/ja/experiences/',
    '/zh-hant/experiences/'
  ]

  const [padding, setPadding] = useState('');

  useEffect(() => {
    switch (true) {
      case pathsBooking2.includes(location.pathname):
        console.log('RPAD0.1');
        setPadding('99px 0 0 0');
        break;
      case pathsPaddings.includes(location.pathname):
        console.log('RPAD1');
        setPadding(0);
        break;
      case _.includes(location.pathname, 'suite') && breakpoints.xs:
        console.log('RPAD2');
        setPadding(0);
        break;
      default:
        console.log('RPAD3');
        setPadding('77px 0 0 0');
    }
  }, [location])

  let isBooking
  switch (true) {
    case pathsBooking.includes(location.pathname):
      isBooking = true
      break
    default:
      isBooking = false
      break
  }

  useEffect(() => {
    if (_.includes(location, 'contact')) {
      setShowToTop(false)
    } else {
      setShowToTop(true)
    }
    if (homepage.indexOf(location.pathname) !== -1) {
      setIsHomepage(true)
    } else {
      setIsHomepage(false)
    }
  }, [location])

  useEffect(() => {
    let language
    switch (true) {
      case _.includes(location.pathname.toLowerCase(), '/ja/'):
        language = 'ja'
        break;
      case _.includes(location.pathname.toLowerCase(), '/zh-hant/'):
        language = 'zh-hant'
        break;
      default:
        language = 'en'
        break;
    }
    i18n.changeLanguage(language)

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 10);

  }, [location])

  const [homeHeader, setHomeHeader] = useState(false);

  useEffect(() => {
    if (homepage.indexOf(location.pathname) === -1) {
      setIsHomepage(false)
    }
    setHomeHeader(true);
  }, []);

  // Function to calculate scrollbar width
  const getScrollbarWidth = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // force scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  // Update body padding-right and overflow when menu state changes
  useEffect(() => {
    const handleMenuChange = () => {
      if (isOpenMenu) {
        const scrollbarWidth = getScrollbarWidth();
        document.body.classList.add('is-open-menu');
        document.body.style.paddingRight = `${scrollbarWidth}px`; // Adjust dynamically
      } else {
        document.body.classList.remove('is-open-menu');
        document.body.style.paddingRight = '0px';
      }
    };

    handleMenuChange();
  }, [isOpenMenu]);

  return (
    <div className={`global-wrapper ${isHomepage ? 'homepage' : ''} ${isOpenMenu ? 'is-open-menu' : ''}`}>
      <Helmet>
        <body className={`${isOpenMenu ? 'is-open-menu' : ''} ${isOpenCalendar ? 'is-open-calendar' : ''}`} />
        <noscript>{`
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.3.15/slick.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        `}</noscript>
      </Helmet>
      {isHomepage ? <>
        {homeHeader && <HeaderDesktop
          location={location}
          onHandleMenu={onHandleMenu}
          isOpenMenu={isOpenMenu}
          isBooking={isBooking}
          t={t}
        />}
      </> : <>
        <HeaderDesktop
          location={location}
          onHandleMenu={onHandleMenu}
          isOpenMenu={isOpenMenu}
          isBooking={isBooking}
          t={t}
        />
      </>}
      <div
        style={{
          padding,
        }}
      >
      </div>
      {children}
      <Footer onHandleMenu={onHandleMenu} />
      {showToTop && <Scroll showBelow={1500} />}
      <Menu
        onHandleMenu={onHandleMenu}
        isOpenMenu={isOpenMenu}
      />
      {
        (breakpoints.sm && isOpenBookingMenu) && (
          <OverlapBooking
            title={'affectionate'}
            onHandledOpenBooking={handledOpenBooking}
            t={t} />
        )
      }
    </div>
  )
}

export default withTrans(Layout)
