import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
} from 'react'

const CalendarContext = createContext('')

const CalendarProvider = ({ children }) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)
  const onHandleCalendar = () => {
    setIsOpenCalendar(!isOpenCalendar)
  }

  const values = {
    onHandleCalendar,
    isOpenCalendar,
  }

  return (
    <CalendarContext.Provider value={values}>
      {children}
    </CalendarContext.Provider>
  )
}

const useCalendar = () => {
  const context = useContext(CalendarContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { CalendarProvider, useCalendar }
