// extracted by mini-css-extract-plugin
export var active = "map-styles-module--active--9abf5";
export var arrow = "map-styles-module--arrow--b8b6f";
export var arrowDisabled = "map-styles-module--arrow--disabled--5f578";
export var arrowLeft = "map-styles-module--arrow--left--486c2";
export var arrowRight = "map-styles-module--arrow--right--6e9b0";
export var blink = "map-styles-module--blink--9841d";
export var bookingBtn = "map-styles-module--booking-btn--67cb5";
export var bookingPageHeader = "map-styles-module--booking-page-header--74bb2";
export var checkout = "map-styles-module--checkout--4a140";
export var commentsSlider = "map-styles-module--commentsSlider--14e84";
export var dot = "map-styles-module--dot--7df60";
export var dots = "map-styles-module--dots--909ac";
export var equallyBound = "map-styles-module--equallyBound--dd5fd";
export var fader = "map-styles-module--fader--4577f";
export var fader__slide = "map-styles-module--fader__slide--8264b";
export var featuredSlider = "map-styles-module--featuredSlider--a7b3e";
export var globalWrapper = "map-styles-module--global-wrapper--f040d";
export var headerIcons = "map-styles-module--header-icons--c2a44";
export var homepage = "map-styles-module--homepage--618a4";
export var homepageReviews = "map-styles-module--homepage-reviews--eceb1";
export var isOpenMenu = "map-styles-module--is-open-menu--70138";
export var keenSlider = "map-styles-module--keen-slider--940c1";
export var lineMove = "map-styles-module--lineMove--02e5a";
export var logo = "map-styles-module--logo--afc9d";
export var map__box = "map-styles-module--map__box--96821";
export var map__card = "map-styles-module--map__card--3330a";
export var map__container = "map-styles-module--map__container--05ed9";
export var map__dot = "map-styles-module--map__dot--df9e4";
export var map__mobile__image = "map-styles-module--map__mobile__image--e926e";
export var map__parent = "map-styles-module--map__parent--6684e";
export var navigationWrapper = "map-styles-module--navigation-wrapper--b1489";
export var scrollNav = "map-styles-module--scrollNav--5613a";
export var scrollNavWrap = "map-styles-module--scrollNavWrap--b7eb9";
export var slickSlide = "map-styles-module--slick-slide--1e331";
export var startTxtArea = "map-styles-module--startTxtArea--5e7a9";